import {useSelector} from "react-redux";

const GuestSection = ({children}) => {
    const logged = useSelector(state => state?.auth?.logged ?? false);

    return !logged && children;

}

export default GuestSection;
