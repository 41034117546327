import {CREATE_ACCOUNT_CTA, ENTER_MAIL_ADDRESS, HERO_LOGIN_IMG, HERO_LOGIN_IMG_ALT, WELCOME_LOGIN} from "./defs";
import React from "react";
import {Field, Form, Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {map} from 'lodash';
import {Alert} from "react-bootstrap";
import {userAuthenticate} from "../store/actions";

export function SignInSection({onWouldSignUp}) {

    const dispatch = useDispatch();

    const authErrors = useSelector(state => state?.auth?.errors ?? []);

    const tryAuthentication = (values) => {
        dispatch(userAuthenticate(values)).then(response => {

        });
    };
    return <section style={{
        height: '100vh'
    }} className="d-flex align-items-center" id="signIn">
        <div id="login" className="container">

            <div className="row justify-content-center">


                <div className="col-xl-10 col-lg-12 col-md-9">

                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block bg-login-image">
                                    <img className="img-fluid" src={HERO_LOGIN_IMG} alt={HERO_LOGIN_IMG_ALT}/>
                                </div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">{WELCOME_LOGIN}</h1>
                                        </div>
                                        <div className="text-center">
                                            {
                                                map(authErrors, el => <Alert size="sm" variant={"danger"}>
                                                    {el[0]}
                                                </Alert>)
                                            }
                                        </div>
                                        <Formik initialValues={{
                                            email: '',
                                            password: '',
                                        }} onSubmit={tryAuthentication}>
                                            <Form className="user">
                                                <div className="form-group">
                                                    <Field
                                                        component="input"
                                                        name="email"
                                                        type="email"
                                                        className="form-control form-control-user"
                                                        id="email-input"
                                                        aria-describedby="emailHelp"
                                                        autoComplete="email"
                                                        placeholder={ENTER_MAIL_ADDRESS}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <Field
                                                        component="input"
                                                        name="password"
                                                        type="password"
                                                        className="form-control form-control-user"
                                                        id="password-input"
                                                        autoComplete="password"
                                                        placeholder="Password"
                                                        required
                                                    />
                                                </div>
                                                <button type="submit" className="btn btn-primary btn-user btn-block">
                                                    Login
                                                </button>
                                            </Form>
                                        </Formik>
                                        <hr/>
                                        {/*<div className="text-center">*/}
                                        {/*    <a className="small" href="#">Forgot Password?</a>*/}
                                        {/*</div>*/}
                                        <div className="text-center">
                                            <a className="small" href="#"
                                               onClick={onWouldSignUp}>{CREATE_ACCOUNT_CTA}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </section>;
}
