import './App.css';
import * as React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import {Layout} from "./Layout";
import {useSelector} from "react-redux";
import {MustLogin} from "./MustLogin";
import OnePageLayout from "./OnePageLayout";
import {LayoutInitializer} from "./OnePageLayout/LayoutInitializer";


function OnePageApp() {

    // const logged = useSelector(state => state?.auth?.logged);

    return <LayoutInitializer>
        <OnePageLayout/>
    </LayoutInitializer>;
}

export default OnePageApp;
