import Axios from "axios";

let tokenInterceptor = null;

export const setupTokenInterceptor = (token) => {
    if (Number.isInteger(tokenInterceptor)) {
        Axios.interceptors.request.eject(tokenInterceptor);
    }

    tokenInterceptor = Axios.interceptors.request.use(config => {
        if (token) {
            config.headers.Authorization = "Bearer " + token
        }
        return config;
    });

}
