import {CREATE_ACCOUNT_CTA, HAVE_AN_ACCOUNT_LOGIN, HERO_LOGIN_IMG, REGISTER_ACCOUNT_CTA} from "./defs";
import React from "react";
import {Field, Form, Formik} from "formik";
import {useDispatch} from "react-redux";
import {signupSchool} from "../store/actions";

export function SignUpSection({onWouldSignIn}) {

    const dispatch = useDispatch();

    return <section style={{
        height: '100vh'
    }} className="d-flex align-items-center" id="signUp">
        <div className="container">

            <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-lg-5 d-none d-lg-block bg-register-image">
                            <img className="img-fluid" src={HERO_LOGIN_IMG}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="p-5">
                                <div className="text-center">
                                    <h1 className="h4 text-gray-900 mb-4">{CREATE_ACCOUNT_CTA}</h1>
                                </div>
                                <Formik initialValues={{
                                    name: '',
                                    first_name: '',
                                    last_name: '',
                                    email: '',
                                    password: '',
                                    password_confirmation: '',
                                    mechanographic_code: '',
                                    sdi_code: '',
                                }} onSubmit={(values) => {
                                    dispatch(signupSchool(values));
                                }}>
                                    <Form className="user">
                                        <div className="form-group">
                                            <Field component="input"
                                                   name="name"
                                                   required type="text" className="form-control form-control-user"
                                                   id="school-name" placeholder="Nome Scuola"/>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <Field component="input"
                                                       name="first_name"
                                                       required type="text" className="form-control form-control-user"
                                                       id="refere-name" placeholder="Nome Referente"/>
                                            </div>
                                            <div className="col-sm-6">
                                                <Field component="input"
                                                       name="last_name"
                                                       required type="text" className="form-control form-control-user"
                                                       id="referer-surname" placeholder="Cognome Referente"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <Field component="input"
                                                   name="email"
                                                   required type="email" className="form-control form-control-user"
                                                   id="email" placeholder="Indirizzo Email"/>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <Field component="input"
                                                       name="password"
                                                       required type="password"
                                                       autocomplete="new-password"
                                                       className="form-control form-control-user"
                                                       id="password" placeholder="Password"/>
                                            </div>
                                            <div className="col-sm-6">
                                                <Field component="input"
                                                       name="password_confirmation"
                                                       autocomplete="new-password"
                                                       required type="password"
                                                       className="form-control form-control-user"
                                                       id="password-confirmation" placeholder="Conferma Password"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <Field component="input"
                                                       name="mechanographic_code"
                                                       required type="text" className="form-control form-control-user"
                                                       id="mec-code" placeholder="Codice Meccanografico"/>
                                            </div>
                                            <div className="col-sm-6">
                                                <Field component="input"
                                                       name="sdi_code"
                                                       required type="text" className="form-control form-control-user"
                                                       id="sdi-code" placeholder="Codice Univoco (SDI)"/>
                                            </div>
                                        </div>

                                        <button type="submit" className="btn btn-primary btn-user btn-block">
                                            {REGISTER_ACCOUNT_CTA}
                                        </button>
                                    </Form>
                                </Formik>
                                <hr/>
                                <div className="text-center">
                                    <a className="small" href="#" onClick={onWouldSignIn}>
                                        {HAVE_AN_ACCOUNT_LOGIN}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>;
}
