import React from "react";
import {Field, Form, Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {sendContactModule} from "../../../store/actions";
import Alert from "react-bootstrap/Alert";

export function ContactModule() {

    const {submitting = false, submitted = false, error = false} = useSelector(state => state?.contactModule);


    const dispatch = useDispatch();

    return <Formik initialValues={{
        name: '',
        email: '',
        subject: '',
        message: '',
    }} onSubmit={(values) => {
        dispatch(sendContactModule(values))
    }}>
        <Form className="php-email-form" role="form">
            {
                !submitted && <>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="name">Nome</label>
                            <Field component="input" required className="form-control"
                                   data-msg="Nome inserire minimo 4 caratteri"
                                   data-rule="minlen:4" id="name" name="name"
                                   type="text"/>
                            <div className="validate"/>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="name">Email</label>
                            <Field component="input" required className="form-control"
                                   data-msg="Inserisci un indirizzo valido"
                                   data-rule="email"
                                   id="email" name="email"
                                   type="email"/>
                            <div className="validate"/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Oggetto</label>
                        <Field component="input" required className="form-control"
                               data-msg="Oggetto: inserire almeno 8 caratteri"
                               data-rule="minlen:4" id="subject" name="subject"
                               type="text"/>
                        <div className="validate"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Messaggio</label>
                        <Field component="textarea" required
                               className="form-control"
                               data-msg="Compilare tutti i campi "
                               data-rule="required"
                               name="message"
                               rows="10"/>
                        <div className="validate"/>
                    </div>
                </>
            }
            <div className="mb-3">
                {
                    submitting && <Alert variant={"info"}>Attendere</Alert>
                }
                {
                    error && <Alert variant={"danger"}>C'è stato un errore nell'invio del modulo. Controlla i dati</Alert>
                }
                {
                    submitted && <Alert variant={"success"}>Il messaggio è stato inviato. Grazie!</Alert>
                }
            </div>
            <div className="text-center">
                <button hidden={submitted} disabled={submitting} type="submit">Invia messaggio</button>
            </div>
        </Form>
    </Formik>;
}
