import {createReducer} from "@reduxjs/toolkit";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {dashboard_storage} from "./index";
import {authLogout, fillUserInfo, sendContactModule, setCrossAuth, signupSchool, userAuthenticate} from "./actions";
import {setupTokenInterceptor} from "./axiosInterceptor";

export const auth = createReducer({
    logged: false,
    user: false,
    token: '',
    errors: [],
}, builder => {
    builder.addCase(setCrossAuth, (state, action) => {
        return {
            ...action.payload,
        }
    });
    builder.addCase(userAuthenticate.fulfilled, (state, action) => {
        if (!(action.payload?.isAxiosError ?? false)) {
            const token = action?.payload?.token;
            setupTokenInterceptor(token)


            // window.hideLogin();
            return {
                ...state,
                ...action.payload,
                errors: []
            };
        }

        return action.payload;

    })
    builder.addCase(userAuthenticate.rejected, (state, action) => {
        return {
            ...state,
            errors: action?.payload?.response?.data?.errors
        }
    })
    builder.addCase("persist/REHYDRATE", (state, action) => {

        const token = action?.payload?.token;
        setupTokenInterceptor(token)

        return state;
    })
    builder.addCase(fillUserInfo, (state, action) => {


        const {setGlobalAuth = true, ...payload} = action.payload;
        const newState = {
            ...state,
            user: payload,
            logged: true,
            errors: []
        };

        if (setGlobalAuth) {
            const value = JSON.stringify({
                user: JSON.stringify(newState.user),
                authToken: JSON.stringify(newState.token)
            });
            dashboard_storage.set("persist:ptbp-auth", value);
        }

        return newState;
    })
    builder.addCase(authLogout, () => {
        dashboard_storage.set("persist:ptbp-auth", "");

        return {
            logged: false,
            user: false,
            token: '',
            errors: [],
        };
    })
    builder.addCase(signupSchool.fulfilled, (state, action) => {
        if (!(action.payload?.isAxiosError ?? false)) {
            const token = action?.payload?.token;
            setupTokenInterceptor(token)

            // window.hideLogin();
            return {
                ...state,
                ...action.payload,
                errors: []
            };
        }

        return action.payload;
    })
});

export const authReducer = persistReducer(
    {storage, key: "ptbp-auth", whitelist: ["user", "token", "logged"]},
    auth
);

export const contentReducer = createReducer({
    entities: [],
    listLoading: false,
    loaded: false,
}, builder => {
    builder.addCase('contents/fulfilled', (state, action) => {
        state.entities = action?.payload?.data ?? [];
        state.loaded = true;

    });
})

export const contactModuleReducer = createReducer({
        submitted: false,
        submitting: false,
        error: false,
    },
    builder => {
        builder.addCase(sendContactModule.pending, (state, action) => {
            return {
                ...state,
                submitting: true
            }
        })
        builder.addCase(sendContactModule.rejected, (state, action) => {
            return {
                ...state,
                submitting: false,
                submitted: false,
                error: true,
            };
        })
        builder.addCase(sendContactModule.fulfilled, (state, action) => {
            return {
                ...state,
                submitting: false,
                submitted: true,
                error: false,
            };
        })
    })
