import React from "react";

export function AboutSection() {
    return <section className="about" id="about">
        <div className="container" data-aos="fade-up">

            <div className="section-title">
                <h2>IL PROGETTO</h2>
            </div>

            <div className="row content align-items-end">
                <div className="col-lg-6">
                    <p className="text-justify">
                        Il progetto <b>Play to be a player: learn for your life</b> nasce dall’esigenza, da
                        parte di
                        scuole ed educatori, di diffondere i valori etici della cittadinanza, della convivenza
                        pacifica
                        e del rispetto culturalenella società moderna.
                    </p>
                    <p className="text-justify">
                        Approvato in risposta al bando <b>POR Puglia FESR-FSE 2014-2020, relativamente
                        all’Azione 1.4.b
                        “Supporto alla generazione di soluzioni innovative a specifici problemi di rilevanza
                        sociale”</b>, l’interventorisponde al fabbisogno espresso dall’Associazione <b>Mamapulia
                        APS</b>
                        e dall’ <b>Istituto Comprensivo (IC) S. Chiara – Pascoli – Altamura di Foggia</b> che
                        hanno
                        manifestato interesse per la realizzazione di sistemi innovativi nel campo della
                        formazione.
                    </p>
                    <div className="row align-items-end">
                        <div className="col-4">
                            <img className="img-fluid" src="assets/img/mama_pulia_logo.png"/>
                        </div>
                        <div className="col-4 text-center">
                            <img style={{
                                height: 100
                            }} className="img-fluid" src="assets/img/nuovo-logo.jpg"/>
                        </div>
                        <div className="col-4">
                            <img className="img-fluid" src="assets/img/logo-santa-chiara.png"/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 pt-4 pt-lg-0 text-center">
                    <p>
                        <img className="img-fluid" src="assets/img/start.png"/>
                    </p>
                </div>
            </div>

        </div>
    </section>;
}
