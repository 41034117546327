import React from "react";

export function CliensSection() {
    return <section className="cliens section-bg" id="cliens">
        <div className="container">

            <div className="row" data-aos="zoom-in">

                <div className="col-lg-4 col-md-3 col-6 d-flex align-items-center justify-content-center">
                    <img alt="INNOLBAS" className="img-fluid" src="assets/img/clients/inno_labs.png"/>
                </div>

                <div className="col-lg-2 col-md-3 col-6 d-flex align-items-center justify-content-center">
                    <img alt="PUGLIA FESR-FSE" className="img-fluid" src="assets/img/clients/por_puglia.png"/>
                </div>

                <div className="col-lg-2 col-md-3 col-6 d-flex align-items-center justify-content-center">
                    <img alt="UNIONE EUROPEA" className="img-fluid" src="assets/img/clients/unione_europea.png"/>
                </div>

                <div className="col-lg-2 col-md-3 col-6 d-flex align-items-center justify-content-center">
                    <img alt="MINISTERO DELLO SVILUPPO ECONOMICO" className="img-fluid"
                         src="assets/img/clients/ministero_economico.png"/>
                </div>

                <div className="col-lg-2 col-md-3 col-6 d-flex align-items-center justify-content-center">
                    <img alt="REGIONE PUGLIA" className="img-fluid" src="assets/img/clients/regione_puglia.png"/>
                </div>


            </div>

        </div>
    </section>;
}
