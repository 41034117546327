import {useSelector} from "react-redux";
import React from "react";

const ProtectedSection = ({children}) => {
    const logged = useSelector(state => state?.auth?.logged ?? false);

    return logged && children;
}

export default ProtectedSection;
