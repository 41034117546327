import React, {useEffect, useState} from "react";
import {HeaderSection} from "./OnePageLayout/HeaderSection";
import {HeroSection} from "./OnePageLayout/HeroSection";
import {MainSection} from "./OnePageLayout/MainSection";
import {FooterSection} from "./OnePageLayout/FooterSection";
import {AuthLayout} from "./OnePageLayout/AuthLayout";
import {useDispatch, useSelector} from "react-redux";
import {authLogout} from "./store/actions";

const OnePageLayout = () => {

    const [authoring, setAuthoring] = useState(false);

    const dispatch = useDispatch();

    const logged = useSelector(state => state?.auth?.logged ?? false);
    const onLogoutRequest = () => {
        dispatch(authLogout());
    }

    useEffect(() => {
        if (window.$('#preloader').length) {
            window.$('#preloader').delay(100).fadeOut('slow', function () {
                window.$(this).remove();
            });
        }
    },[1])

    return (
        <>
            <HeaderSection onLogoutRequest={onLogoutRequest} fixedColor={authoring} onNormalRequest={() => setAuthoring(false)} onAuthRequest={() => setAuthoring(true)}/>
            {
                (!authoring || logged) && [
                    <HeroSection/>,
                    <MainSection/>,
                ]
            }
            {
                (authoring && !logged) && <AuthLayout/>
            }
            <FooterSection/>
            <a className="back-to-top" href="#"><i className="ri-arrow-up-line"/></a>
            <div id="preloader"/>
        </>
    )

}


export default OnePageLayout;
