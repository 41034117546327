import React from "react";

export function FAQSection() {
    return <section className="faq section-bg" id="faq">
        <div className="container" data-aos="fade-up">

            <div className="section-title">
                <h2>Domade frequenti</h2>
                <p></p>
            </div>

            <div className="faq-list">
                <ul>
                    <li data-aos="fade-up" data-aos-delay="100">
                        <i className="bx bx-help-circle icon-help"></i> <a className="collapse"
                                                                           data-toggle="collapse"
                                                                           href="#faq-list-1">Come viene
                        rispettata
                        la
                        privacy degli utenti? <i className="bx bx-chevron-down icon-show"></i><i
                            className="bx bx-chevron-up icon-close"></i></a>
                        <div className="collapse show" data-parent=".faq-list" id="faq-list-1">
                            <p>
                                I dati profilati tramite piattaforma sono visibili ai soli operatori
                                preventivamente
                                autorizzati dell'istututo scolastico.
                                <br/>In nessun altro modo è possibile visiorare i dati o associarli a
                                profili utenti
                                conosciuti.
                            </p>
                        </div>
                    </li>

                    <li data-aos="fade-up" data-aos-delay="200">
                        <i className="bx bx-help-circle icon-help"></i> <a className="collapsed"
                                                                           data-toggle="collapse"
                                                                           href="#faq-list-2">La piattaforma
                        può
                        essere
                        utilizzata solo a scuola o anche a casa, con i genitori S?<i
                            className="bx bx-chevron-down icon-show"></i><i
                            className="bx bx-chevron-up icon-close"></i></a>
                        <div className="collapse" data-parent=".faq-list" id="faq-list-2">
                            <p>
                                La piattaforma è web-based, pertanto è utilizzabile ovunque.
                            </p>
                        </div>
                    </li>

                    <li data-aos="fade-up" data-aos-delay="300">
                        <i className="bx bx-help-circle icon-help"></i> <a className="collapsed"
                                                                           data-toggle="collapse"
                                                                           href="#faq-list-3">E' possibile
                        giocare
                        più di
                        una
                        volta? <i className="bx bx-chevron-down icon-show"></i><i
                            className="bx bx-chevron-up icon-close"></i></a>
                        <div className="collapse" data-parent=".faq-list" id="faq-list-3">
                            <p>
                                Le sessioni di gioco sono illimitate, ogni sessione raccoglierà in modo
                                univoco i
                                dati
                                raccolti per essere elaborati in modo distinto ogni volta
                            </p>
                        </div>
                    </li>

                    <li data-aos="fade-up" data-aos-delay="400">
                        <i className="bx bx-help-circle icon-help"></i> <a className="collapsed"
                                                                           data-toggle="collapse"
                                                                           href="#faq-list-4">Come posso
                        effettuare
                        l'iscrizione al vostro sito/piattaforma? <i
                            className="bx bx-chevron-down icon-show"></i><i
                            className="bx bx-chevron-up icon-close"></i></a>
                        <div className="collapse" data-parent=".faq-list" id="faq-list-4">
                            <p>
                                Le registrazione è possibile tramite apposito modulo di registrazione.
                                Attualmente la piattaforma permetterà l'iscrizione ai soli istituti
                                scolastici.
                            </p>
                        </div>
                    </li>

                    <li data-aos="fade-up" data-aos-delay="500">
                        <i className="bx bx-help-circle icon-help"></i> <a className="collapsed"
                                                                           data-toggle="collapse"
                                                                           href="#faq-list-5">Il gioco ha
                        una
                        finalità
                        diagnostica ? <i className="bx bx-chevron-down icon-show"></i><i
                            className="bx bx-chevron-up icon-close"></i></a>
                        <div className="collapse" data-parent=".faq-list" id="faq-list-5">
                            <p>
                                No, solo conoscitiva
                            </p>
                        </div>
                    </li>

                    <li data-aos="fade-up" data-aos-delay="500">
                        <i className="bx bx-help-circle icon-help"></i> <a className="collapsed"
                                                                           data-toggle="collapse"
                                                                           href="#faq-list-5">il gioco va
                        bene anche
                        per
                        bambini di qualsiasi età ? <i className="bx bx-chevron-down icon-show"></i><i
                            className="bx bx-chevron-up icon-close"></i></a>
                        <div className="collapse" data-parent=".faq-list" id="faq-list-5">
                            <p>
                                Il gioco è modellato per i bambini di età 5/7 anni, non è vietato farci
                                giocare
                                bambini
                                più grandi
                            </p>
                        </div>
                    </li>


                </ul>
            </div>

        </div>
    </section>;
}
