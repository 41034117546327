import React from "react";

export function MethodSection() {
    return <section className="skills" id="metodo">
        <div className="container" data-aos="fade-up">

            <div className="row">
                <div className="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
                    <img alt="" style={{
                        objectFit: "cover",
                        height: '100%',
                        width: '100%'
                    }} src="assets/img/metodologia.jpg"/>
                </div>
                <div className="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
                    <h3><b>Metodologia</b> adottata</h3>
                    <p className="">
                        Allo scopo di diffondere in maniera efficace i valori del dialogo culturale e di
                        contrastare le
                        problematiche più rilevanti della società odierna quali l’antisocialità,
                        il disinteresse, la diffidenza verso il prossimo percepito come diverso,<b>GAMENGO Play
                        to be a
                        player </b>ha realizzato:<br/>
                        <li>
                            Una piattaforma web-based pensata ad hoc per il mondo "scuola", capace non solo di
                            mettere a
                            disposizione di tutti gli utenti registrati contenuti didattici formativi
                            ma anche di profilare, monitorare ed elaborare dati provenienti da piattaforme di
                            gioco proprie
                            o di terze parti.
                            La piattaforma nel pieno rispetto della privacy è dei ruoli(scuola, responsabile,
                            educatore)
                            è in grado di gestire dinamicamente utenti (alunni e classi) e figure prossionali
                            coinvolte ed è
                            in grado di immagazzinare dati, provenienti da sessioni live di gioco, elaborarli
                            (tramite
                            algoritmi) e metterli a disposizione dei formatori autorizzati.<br/>

                        </li>

                        <li>

                            Un gioco multimediale per ragazzi di età compresa tra i 6 ed 8 anni attraverso il
                            quale i
                            ragazzi vengono coinvolti in percorsi virtuali finalizzati
                            allo sviluppo di un apprendimento collaborativo e interattivo.<br/>
                        </li>

                        <li>
                            Contenuti formativi di altissimo profilo realizzati grazie alla collaborazione della
                            Università
                            degli Studi di Foggia

                        </li>


                    </p>

                    <div className="skills-content">

                        <div className="progress">
                            <span className="skill">Nuove tecnologie <i className="val">100%</i></span>
                            <div className="progress-bar-wrap">
                                <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="100"
                                     className="progress-bar"
                                     role="progressbar"></div>
                            </div>
                        </div>

                        <div className="progress">
                            <span className="skill">Gamification <i className="val">100%</i></span>
                            <div className="progress-bar-wrap">
                                <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="100"
                                     className="progress-bar"
                                     role="progressbar"></div>
                            </div>
                        </div>

                        <div className="progress">
                            <span className="skill">Contenuti formativi <i className="val">100%</i></span>
                            <div className="progress-bar-wrap">
                                <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="100"
                                     className="progress-bar"
                                     role="progressbar"></div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>

        </div>
    </section>;
}
