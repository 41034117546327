import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import Axios from "axios";
import {baseApi} from "./defs";
import {setupTokenInterceptor} from "./axiosInterceptor";


export const setCrossAuth = createAction('auth/crossAuth');
export const authenticateCrossAuth = createAction('auth/authenticateCrossAuth');
export const userAuthenticate = createAsyncThunk('auth/userLogin', ({email, password}, thunkAPI) => {
    return Axios.post(baseApi + "login", {
        email,
        password
    }, {
        headers: {
            "Accept": "application/json"
        }
    }).then(value => {
        const token = value?.data?.token;

        setupTokenInterceptor(token)

        Axios.get(baseApi + "me", {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + token
            }
        }).then(value => {
            thunkAPI.dispatch(fillUserInfo(value.data));
        })


        return value.data;
    }).catch(reason => thunkAPI.rejectWithValue(reason))
});
export const fillUserInfo = createAction('auth/fillUserInfo');
export const authLogout = createAction('auth/logout');

export const signupSchool = createAsyncThunk('auth/signupSchool', (arg, thunkAPI) => {
    return Axios.post(baseApi + "schoolSignup", arg, {
        headers: {
            "Accept": "application/json"
        }
    }).then(value => {
        const token = value?.data?.token;

        setupTokenInterceptor(token)

        Axios.get(baseApi + "me", {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + token
            }
        }).then(value => {
            thunkAPI.dispatch(fillUserInfo({
                ...value.data,
                setGlobalAuth: false
            }));
        })


        return value.data;
    }).catch(reason => thunkAPI.rejectWithValue(reason))
});


export const getContents = createAsyncThunk('contents', (arg, thunkAPI) => {
    return Axios.get(baseApi + "content_post?per_page=999", {
        headers: {
            "Accept": "application/json",
        }
    }).then(value => value.data).catch(reason => {
    })
})

export const sendContactModule = createAsyncThunk('contactModule/send', (arg, thunkAPI) => {
    return Axios.post(baseApi + 'contact-module',arg).then(r => {
        return r.data;
    }).catch(reason => thunkAPI.rejectWithValue(reason));
})
