import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {combineReducers} from "redux";
import {persistStore} from "redux-persist";
import {authReducer, contactModuleReducer, contentReducer} from "./store";
import {mapValues} from 'lodash';


export const rootReducer = combineReducers({
    auth: authReducer,
    contents: contentReducer,
    contactModule: contactModuleReducer
})

const middleware = [
    ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: true
    }),
];

export const store = configureStore({
    reducer: rootReducer,
    middleware
});


const CrossStorageClient = require('cross-storage').CrossStorageClient;
export const dashboard_storage = new CrossStorageClient(process.env.REACT_APP_CROSS_STORAGE_APP);

dashboard_storage.onConnect().then(() => {
    const persist = dashboard_storage.get('persist:ptbp-auth').then(value => {
        localStorage.setItem('persist:ptbp-auth', value);
        const parse = mapValues(JSON.parse(value), el => JSON.parse(el));
        store.dispatch({
            type: 'persist/REHYDRATE',
            payload: {
                ...parse,
                logged: !!parse.user ?? false,
            },
            key: 'ptbp-auth'
        })
        window.initPage(window.$);

    }).catch(reason => {
        window.initPage(window.$);
    });
});

export const persistor = persistStore(store);

