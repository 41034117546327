import React from "react";
import ProtectedSection from "../ProtectedSection";
import {GAME_LOGO, PARENTS_SURVEY, PROFESSOR_SURVEY} from "./defs";
import GuestSection from "../GuestSection";

export const HeaderSection = ({onAuthRequest, onLogoutRequest, onNormalRequest, fixedColor = false}) => {
    const heroHref = "#hero";
    return <header
        className="fixed-top fixed-header-scrolled"
        id="header"
    >
        <div className="container d-flex align-items-center">

            <h1 className="logo mr-auto">
                <a onClick={onNormalRequest} className="logo mr-auto" href="#">
                    <img alt="" className="img-fluid" src={GAME_LOGO}/>
                </a>
            </h1>

            <nav className="nav-menu d-none d-lg-block">
                <ul>
                    <li><a onClick={onNormalRequest} href={heroHref}>Home</a></li>
                    <li className="drop-down">
                        <a onClick={onNormalRequest} href={"#about"}>Il progetto</a>
                        <ul>
                            <a onClick={onNormalRequest} href={"#about"}>Il progetto</a>
                            <li><a onClick={onNormalRequest} href={"#obiettivi"}>Gli obiettivi</a></li>
                            <li><a onClick={onNormalRequest} href={"#metodo"}>Il metodo</a></li>
                        </ul>
                    </li>
                    <li><a onClick={onNormalRequest} href={"#team"}>I partner</a></li>
                    <li><a onClick={onNormalRequest} href={"#portfolio"}>Video formativi</a></li>
                    <li><a onClick={onNormalRequest} href={"#contact"}>Contatti</a></li>
                    <li className="drop-down"><a href="#">Questionari</a>
                        <ul>
                            <li><a href={PROFESSOR_SURVEY} target="_blank">Docenti</a></li>
                            <li><a href={PARENTS_SURVEY} target="_blank">Genitori</a></li>
                        </ul>
                    </li>
                </ul>
            </nav>

            <div className="mobile-nav-overly">
                <nav className="mobile-nav d-lg-none">
                    <ul>
                        <li><a onClick={onNormalRequest} href={heroHref}>Home</a></li>
                        <li className="drop-down">
                            <a onClick={onNormalRequest} href={"#about"}>Il progetto</a>
                            <ul>
                                <a onClick={onNormalRequest} href={"#about"}>Il progetto</a>
                                <li><a onClick={onNormalRequest} href={"#obiettivi"}>Gli obiettivi</a></li>
                                <li><a onClick={onNormalRequest} href={"#metodo"}>Il metodo</a></li>
                            </ul>
                        </li>
                        <li><a onClick={onNormalRequest} href={"#team"}>I partner</a></li>
                        <li><a onClick={onNormalRequest} href={"#portfolio"}>Video formativi</a></li>
                        <li><a onClick={onNormalRequest} href={"#contact"}>Contatti</a></li>
                        <li className="drop-down"><a href="#">Questionari</a>
                            <ul>
                                <li><a href={PROFESSOR_SURVEY} target="_blank">Docenti</a></li>
                                <li><a href={PARENTS_SURVEY} target="_blank">Genitori</a></li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>

            <GuestSection>
                <a
                    className="get-started-btn scrollto"
                    href={"#signIn"}
                    onClick={onAuthRequest}
                    id="login-div"
                >
                    Login/Registrati
                </a>
            </GuestSection>
            <ProtectedSection>
                <a
                    className="get-started-btn scrollto"
                    target="_blank"
                    href={process.env.REACT_APP_DASHBOARD}
                >Dashboard</a>
                <a
                    className="get-started-btn scrollto"
                    onClick={() => {
                        onNormalRequest();
                        onLogoutRequest()
                    }} href={"#hero"}
                >Logout</a>
            </ProtectedSection>
        </div>
    </header>;
};
