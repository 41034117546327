import React, {useState} from "react";
import {SignInSection} from "./SignInSection";
import {SignUpSection} from "./SignUpSection";

export function AuthLayout() {
    const [wouldSignIn, setWouldSignIn] = useState(true);
    return (
            wouldSignIn &&
            <SignInSection
                onWouldSignUp={() => setWouldSignIn(false)}
            />
        ) ||
        <SignUpSection
            onWouldSignIn={() => setWouldSignIn(true)}
        />;
}
