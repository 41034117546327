import React from "react";
import {ContactModule} from "./ContactSection/ContactModule";

export function ContactSection() {
    return <section className="contact" id="contact">
        <div className="container" data-aos="fade-up">

            <div className="section-title">
                <h2>Contatti</h2>
                <p/>
            </div>

            <div className="row">

                <div className="col-lg-5 d-flex align-items-stretch">
                    <div className="info">
                        <div className="address">
                            <i className="icofont-google-map"/>
                            <h4>Indirizzo:</h4>
                            <p> Via Luigi Rovelli, 48 - 71122 Foggia</p>
                        </div>

                        <div className="email">
                            <i className="icofont-envelope"/>
                            <h4>Email:</h4>
                            <p>info@adtm.it</p>
                        </div>

                        <div className="phone">
                            <i className="icofont-phone"/>
                            <h4>Tel:</h4>
                            <p>0881 638927</p>
                        </div>

                        <iframe allowFullScreen
                                frameBorder="0"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2989.9485515333613!2d15.522123115428105!3d41.46203117925731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1339d8e7f720938b%3A0x311b37f35e159461!2sVia%20Luigi%20Rovelli%2C%2048%2C%2071122%20Foggia%20FG!5e0!3m2!1sit!2sit!4v1603695371025!5m2!1sit!2sit"
                                style={{border: 0, width: "100%", height: 290,}}/>
                    </div>

                </div>

                <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                    <ContactModule/>
                </div>

            </div>

        </div>
    </section>;
}
