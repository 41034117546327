import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {groupBy, keys, map} from "lodash";
import {getContents} from "./store/actions";

export function Layout() {

    const token = useSelector(state => state?.auth?.token);

    const contentsState = useSelector(state => state?.contents ?? {});
    const contents = contentsState?.entities ?? [];
    const loaded = contentsState?.loaded ?? false;

    const dispatch = useDispatch();

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        window.$(window).on('load', function () {
            setIsLoaded(true)
        });

        return () => window.$(window).off('load')
    },[1])

    useEffect(() => {

        if (!loaded && isLoaded) {
            dispatch(getContents());
        }

        if (contents && loaded) {
            setContentCategories(groupBy(contents, el => el?.category_data?.slug));
        }
    }, [contents, isLoaded, loaded])


    const [contentsCategories, setContentCategories] = useState({});

    useEffect(() => {
        if (keys(contentsCategories).length > 0) {
            window.initPortfolio();
        }
    }, [contentsCategories]);

    return <div className="container" data-aos="fade-up">

        <div className="section-title">
            <h2>Portfolio</h2>
            <p>Video realizzati dalla Università degli Studi di Foggia</p>
        </div>

        <ul id="portfolio-flters" className="row justify-content-center" data-aos="fade-up" data-aos-delay="100">
            <li data-filter="*" className="filter-active col-auto">Tutti</li>
            {
                map(contentsCategories, (el, slug) => {
                    return <li data-filter={`.filter-${slug}`}>{el[0]?.category_data?.title}</li>
                })
            }
        </ul>

        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
            {
                contents.map(el => {
                    return <div style={{paddingBottom: 56}}
                                className={`col-lg-4 col-md-6 col-xs-12 portfolio-item filter-${el?.category_data?.slug}`}>
                        <div className="portfolio-img">
                            {/*<img src="assets/img/portfolio/portfolio-1.jpg" className="img-fluid"*/}
                            {/*                                alt=""/>*/}
                            <video style={{width: '100%'}} controls>
                                <source src={el?.media?.src} type="video/mp4"/>
                            </video>
                        </div>
                        <div className="portfolio-info">
                            <h4>{el.title}</h4>
                            <p>{el.description}</p>
                            {/*<a href="assets/img/portfolio/portfolio-1.jpg" data-gall="portfolioGallery"*/}
                            {/*   className="venobox preview-link" title="App 1"><i className="bx bx-plus"></i></a>*/}
                            {/*<a href="portfolio-details.html" className="details-link" title="More Details"><i*/}
                            {/*    className="bx bx-link"></i></a>*/}
                        </div>
                    </div>
                })
            }
        </div>

    </div>;
}
