import React from "react";

export function ObjectivesSection() {
    return <section className="why-us section-bg" id="obiettivi">
        <div className="container-fluid" data-aos="fade-up">

            <div className="row">

                <div
                    className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                    <div className="content">
                        <h3>Gli obiettivi<strong> del progetto</strong></h3>
                        <p>
                            L’obiettivo del progetto è quello di ridare centralità, nella formazione dei più
                            giovani, a
                            temi di interesse sociale quali
                            la prosocialità, il dialogo e il rispetto culturale in una società che si fa sempre
                            più
                            complessa e multiculturale.
                            <br/> <b> Di seguito i punti centrali dell'iniziativa:</b>
                        </p>
                    </div>

                    <div className="accordion-list">
                        <ul>
                            <li>
                                <a className="collapse" data-toggle="collapse"
                                   href="#accordion-list-1"><span>01</span>
                                    Target "Bambini scuola primaria" <i className="bx bx-chevron-down icon-show"/><i className="bx bx-chevron-up icon-close"/>
                                </a>
                                <div className="collapse show" data-parent=".accordion-list"
                                     id="accordion-list-1">
                                    <p>
                                        L’intervento decide di focalizzarsi in particolar modo sull'educazione
                                        dei
                                        bambini delle scuole primarie con la finalità di costruire personalità
                                        capaci di
                                        desiderare il la relazione, la convivenza pacifica e la realizzazione di
                                        una
                                        società dove sia bello vivere insieme. </p>
                                </div>
                            </li>

                            <li>
                                <a className="collapsed" data-toggle="collapse"
                                   href="#accordion-list-2"><span>02</span>
                                    Sfruttare le nuove tecnologie<i
                                        className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-chevron-up icon-close"></i></a>
                                <div className="collapse" data-parent=".accordion-list" id="accordion-list-2">
                                    <p>
                                        Allo stesso tempo, scopo dell’iniziativa è anche quello di sottolineare
                                        il ruolo
                                        fondamentale che hanno la scuola e gli educatori nella costruzione delle
                                        personalità dei giovani attraverso l’uso di soluzioni creative e
                                        innovative come
                                        quelle offerte dalle nuove tecnologie. </p>
                                </div>
                            </li>

                            <li>
                                <a className="collapsed" data-toggle="collapse"
                                   href="#accordion-list-3"><span>03</span>
                                    Coinvolgimento e partecipazione <i
                                        className="bx bx-chevron-down icon-show"></i><i
                                        className="bx bx-chevron-up icon-close"></i></a>
                                <div className="collapse" data-parent=".accordion-list" id="accordion-list-3">
                                    <p>
                                        L’introduzione di strumenti multimediali nella didattica è infatti uno
                                        mezzo
                                        efficace per favorire, nei cittadini europei, l'acquisizione di
                                        competenze
                                        trasversali in maniera coinvolgente e partecipativa. </p>
                                </div>
                            </li>

                        </ul>
                    </div>

                </div>

                <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
                     data-aos="zoom-in" data-aos-delay="150"
                     style={{backgroundImage: 'url("assets/img/progetto.png")'}}>
                    &nbsp;
                </div>
            </div>

        </div>
    </section>;
}
