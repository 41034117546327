export const HERO_LOGIN_IMG = "assets/img/hero-img-login.png";
export const HERO_LOGIN_IMG_ALT = "hero img";
export const ENTER_MAIL_ADDRESS = "Inserisci indirizzo email";
export const WELCOME_LOGIN = 'Benvenuto su Gamengo!';
export const CREATE_ACCOUNT_CTA = 'Sei una scuola? Crea un account!';
export const GAME_LOGO = "assets/img/gamengo_logo.png";
export const PROFESSOR_SURVEY = "https://it.surveymonkey.com/r/L63WGG2";
export const PARENTS_SURVEY = "https://it.surveymonkey.com/r/HLTXZSG";
export const HAVE_AN_ACCOUNT_LOGIN = "Hai già un account? Effettua il login!"
export const REGISTER_ACCOUNT_CTA = "Registra scuola"
