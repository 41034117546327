import React from "react";

export function TeamSection() {
    return <section className="team section-bg" id="team">
        <div className="container" data-aos="fade-up">

            <div className="section-title text-justify ">
                <h2 className="text-center">Partner</h2>
                <p>Nella sua realizzazione, il progetto vede la collaborazione di partner appartenenti al
                    mondo
                    delle
                    piccole/medie imprese e degli organismi di ricerca:<br/><br/>

                    <li>L'ente di consulenza e formazione A.D.T.M. S.r.l.</li>
                    <li>L'organismo di ricerca Università degli Studi di Foggia</li>
                    <li>L'ente di consulenza e formazione Alfa Consult</li>
                    <li>La ditta di consulenza Progetto azienda di R.V.</li>
                    <li>La Società di sviluppo e consulenza IT Argomedia.it scarl</li>
                    <li>L'Associazione di Promozione Sociale Mamapulia</li>
                    <li>Il Distretto tecnologico INNOLAAV</li>
                    <br/>
                    <p>
                        Le società A.D.T.M. S.r.l. e Alfa Consult, accreditate quali Enti di formazione,
                        sono in
                        possesso di
                        know how specifico legato alla progettazione, erogazione, monitoraggio e valutazione
                        di
                        percorsi
                        formativi.
                        Gli Enti hanno partecipato, spesso, a progetti in collaborazione con le istituzioni
                        scolastiche, in
                        seguito ai quali hanno acquisito conoscenze e competenze specifiche relative alle
                        esigenze
                        dell’istituzione scolastica e dei suoi destinatari finali. L’RTS include, inoltre,
                        un
                        Laboratorio di
                        Ricerca, l’Università di Foggia, in possesso di laboratori ed expertise di didattica
                        sperimentale e
                        innovativa.
                        Rientrano, inoltre, nella compagine dell’RTS, due software house con specifiche
                        competenze e
                        tecnologie in materia di programmazione di: Argomedia Soc. Coop. e Progetto Azienda
                        di
                        Romolo
                        Valzano. INNOVAAL Scarl è una Partnership pubblico-privata, tipica di un Distretto
                        Tecnologico (con
                        22 imprese e 2 Enti Pubblici di ricerca), che opera nel settore dei prodotti e dei
                        servizi
                        tecnologicamente avanzati per l'Active & Assisted Living, mentre Mamapulia è una
                        associazione made
                        in Puglia di promozione sociale e culturale che sostiene l'importanza della
                        condivisione
                        e della
                        valorizzazione del patrimonio culturale pugliese in Italia e all'estero come
                        principale
                        veicolo di
                        conoscenza, formazione e cooperazione tra le culture.
                    </p>
                </p>
            </div>
            <section className="cliens section-bg" id="partner">
                <div className="container">

                    <div className="row" data-aos="zoom-in">

                        <div
                            className="col-lg-4 col-md-3 col-6 d-flex align-items-center justify-content-center">
                            <img alt="" className="img-fluid" src="assets/img/clients/partner/adtm.png"/>
                        </div>

                        <div
                            className="col-lg-4 col-md-3 col-6 d-flex align-items-center justify-content-center">
                            <img alt="" className="img-fluid" src="assets/img/clients/partner/uni_fg.png"/>
                        </div>

                        <div
                            className="col-lg-4 col-md-3 col-6 d-flex align-items-center justify-content-center">
                            <img alt="" className="img-fluid"
                                 src="assets/img/clients/partner/mamapulia.png"/>
                        </div>
                        <div
                            className="col-lg-3 col-md-3 col-6 d-flex align-items-center justify-content-center">
                            <img alt="Argomedia.it Foggia" className="img-fluid"
                                 src="assets/img/clients/partner/argomedia.png"/>
                        </div>
                        <div
                            className="col-lg-3 col-md-3 col-6 d-flex align-items-center justify-content-center">
                            <img alt="" className="img-fluid"
                                 src="assets/img/clients/partner/alfaconsult.png"/>
                        </div>

                        <div
                            className="col-lg-3 col-md-3 col-6 d-flex align-items-center justify-content-center">
                            <img alt="REGIONE PUGLIA" className="img-fluid"
                                 src="assets/img/clients/partner/progetto_azienda.png"/>
                        </div>
                        <div
                            className="col-lg-3 col-md-3 col-6 d-flex align-items-center justify-content-center">
                            <img alt="REGIONE PUGLIA" className="img-fluid"
                                 src="assets/img/clients/partner/mobile_idea.png"/>
                        </div>


                    </div>

                </div>
            </section>

        </div>

    </section>;
}
