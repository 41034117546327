import React, {useEffect} from "react";

export function HeroSection() {

    useEffect(() => {
        setTimeout(() => {
            window.$('.venobox-react').venobox({
                'share': false
            });
        })
    },[1])

    return <section className="d-flex align-items-center" id="hero">

        <div className="container">
            <div className="row">
                <div
                    className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                    data-aos="fade-up" data-aos-delay="200">
                    <h1>PLAY TO BE A PLAYER</h1>
                    <h2 className="mb-3"> Learn for your life</h2>
                    <div className="mb-3" style={{color: "#fff"}}>
                        POR Puglia FESR FSE 2014-2020 – Fondo Europeo Sviluppo Regionale<br/>
                        Asse I “Ricerca, sviluppo tecnologico e innovazione”<br/>
                        Azione 1.4 “Promozione di nuovi mercati per l’innovazione”<br/>
                    </div>
                    <div className="d-lg-flex">
                        <a className="btn-get-started scrollto" href="#about">Scopri di più</a>
                        <a className="venobox venobox-react btn-watch-video" data-autoplay="true"
                           data-vbtype="video" href="https://youtu.be/fnKHjEXD370"> Vedi video <i
                            className="icofont-play-alt-2"/></a>
                    </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                    <img alt="" className="img-fluid animated" src="assets/img/gamengo_slide.png"/>
                </div>
            </div>
        </div>

    </section>;
}
