import React from "react";
import {PortfolioSection} from "./MainSection/PortfolioSection";
import {ContactSection} from "./MainSection/ContactSection";
import {FAQSection} from "./MainSection/FAQSection";
import {TeamSection} from "./MainSection/TeamSection";
import {CallToActionSection} from "./MainSection/CallToActionSection";
import {MethodSection} from "./MainSection/MethodSection";
import ProtectedSection from "../ProtectedSection";
import {CliensSection} from "./MainSection/CliensSection";
import {AboutSection} from "./MainSection/AboutSection";
import {ObjectivesSection} from "./MainSection/ObjectivesSection";

export function MainSection() {
    return <main id="main">
        <CliensSection/>
        <AboutSection/>
        <ObjectivesSection/>
        <MethodSection/>
        {/*<CallToActionSection/>*/}
        <TeamSection/>
        <FAQSection/>
        <ContactSection/>
        <PortfolioSection/>
    </main>;
}
